module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RAWIC - Rafał Wichowski","short_name":"RAWIC","start_url":"/","background_color":"#111111","theme_color":"#111111","display":"fullscreen","icon":"./src/images/icon.png","icons":[{"src":"./src/images/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"./src/images/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"./src/images/icons/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"./src/images/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./src/images/icons/icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"./src/images/icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"./src/images/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"./src/images/icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","pl"],"defaultLanguage":"en","siteUrl":"https://rawic.me/","trailingSlash":"always","redirect":false,"i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","pl"],"interpolation":{"escapeValue":false},"detection":{"order":["querystring"]}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
