// extracted by mini-css-extract-plugin
export var arrowsBox = "home-module--arrows-box--3c895";
export var articleBox = "home-module--article-box--89471";
export var articleHeader = "home-module--article-header--77cb8";
export var btn = "home-module--btn--d9723";
export var experienceBox = "home-module--experience-box--a3f2b";
export var heart = "home-module--heart--4b1b0";
export var highlight = "home-module--highlight--5e201";
export var me = "home-module--me--ef38c";
export var projectList = "home-module--project-list--15d82";
export var skillsList = "home-module--skills-list--0d8f3";
export var title = "home-module--title--befec";