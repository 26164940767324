export const ROUTES = [
    {
        path: '/',
        key: 'greetings',
    },
    {
        path: '/portfolio/',
        key: 'portfolio',
    },
    {
        path: '/personal-projects/',
        key: 'my-projects',
    },
];
