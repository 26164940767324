// extracted by mini-css-extract-plugin
export var logo = "portfolio-module--logo--18e6b";
export var logoAber = "portfolio-module--logo-aber--2e542";
export var logoCoindeal = "portfolio-module--logo-coindeal--dff35";
export var logoFuturenet = "portfolio-module--logo-futurenet--f5809";
export var logoInteracto = "portfolio-module--logo-interacto--59b7a";
export var logoMalpin = "portfolio-module--logo-malpin--6d1de";
export var logoMarineHarvest = "portfolio-module--logo-marine-harvest--8e648";
export var logoMoreFromVr = "portfolio-module--logo-more-from-vr--70ca2";
export var logoProfabrica = "portfolio-module--logo-profabrica--c0a6c";
export var logoRealityBusters = "portfolio-module--logo-reality-busters--4c34f";
export var logoRodzicowo = "portfolio-module--logo-rodzicowo--24364";
export var logoRootWoodStudio = "portfolio-module--logo-root-wood-studio--3033e";
export var personal = "portfolio-module--personal--a8c6d";
export var portfolioList = "portfolio-module--portfolio-list--de108";
export var singleProject = "portfolio-module--single-project--d706d";